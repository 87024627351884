import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import { Modal, Button, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; 
import dayjs from 'dayjs'; 
import 'dayjs/locale/fr'; 
import './App.css'; // Importez votre fichier CSS ici

const App = () => {
  const [monitoringData, setMonitoringData] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (service) => {
    setSelectedService(service);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

/*   useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'MonitoringStatus'), (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        const timestamp = docData.timestamp ? docData.timestamp.toDate() : new Date();
        return {
          id: doc.id,
          ...docData,
          ServiceTag: docData.ServiceTag.replace(/_/g, ' '),
          timestamp,
        };
      });

      // Trier les données pour avoir Elisath à false en premier
      const sortedData = data.sort((a, b) => (a.Services.Elisath === false && b.Services.Elisath === true ? -1 : 1));

      // Ajouter 🚨 devant le ServiceTag si Elisath est false
      const updatedData = sortedData.map((item) => ({
        ...item,
        ServiceTag: item.Services.Elisath === false ? `🚨 ${item.ServiceTag}` : item.ServiceTag
      }));

      setMonitoringData(updatedData);
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, []); */


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'MonitoringStatus'), (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        const timestamp = docData.timestamp ? docData.timestamp.toDate() : new Date();
        return {
          id: doc.id,
          ...docData,
          ServiceTag: docData.ServiceTag.replace(/_/g, ' '),
          timestamp,
        };
      });
  
      // Trier les données pour avoir Elisath à false en premier, puis les timestamps dépassant 5 minutes
      const sortedData = data.sort((a, b) => {
        const now = dayjs();
        const aOlderThan5Minutes = now.diff(dayjs(a.Timestamp), 'minute') > 5;
        const bOlderThan5Minutes = now.diff(dayjs(b.Timestamp), 'minute') > 5;
  
        // Priorité : Elisath à false, puis timestamps dépassant 5 minutes
        if (a.Services.Elisath === false && b.Services.Elisath === true) return -1;
        if (a.Services.Elisath === true && b.Services.Elisath === false) return 1;
        if (aOlderThan5Minutes && !bOlderThan5Minutes) return -1;
        if (!aOlderThan5Minutes && bOlderThan5Minutes) return 1;
  
        return 0; // Si tout est égal, laisser dans l'ordre d'origine
      });
  
      // Ajouter 🚨 devant le ServiceTag si Elisath est false
      const updatedData = sortedData.map((item) => ({
        ...item,
        ServiceTag: item.Services.Elisath === false ? `🚨 ${item.ServiceTag}` : item.ServiceTag
      }));
  
      setMonitoringData(updatedData);
    });
  
    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, []);

  
  const getBackgroundColor = (timestamp) => {
    const now = dayjs();
    const lastUpdated = dayjs(timestamp);
    const diffInMinutes = now.diff(lastUpdated, 'minute');
    return diffInMinutes > 5 ? '#fdd' : '#f5f5f5';
  };

  return (
    <div className="app-container">
      {/* Header */}
      <Box className="header">
        Surveillance des Services & IP
      </Box>

      <Box className="main-content">
        <div className="service-grid">
          {monitoringData.map((doc) => (
            <Box
              key={doc.ServiceTag}
              className="service-box"
              sx={{
                backgroundColor: getBackgroundColor(doc.timestamp),
              }}
            >
              {/* Affichage du nom du ServiceTag */}
              <Typography variant="h6">{doc.ServiceTag}</Typography>

              {/* Affichage du timestamp du document */}
              <Typography
                variant="body2"
                className={dayjs().diff(dayjs(doc.Timestamp), 'minute') > 5 ? 'timestamp red-text' : 'timestamp'}
              >
                Dernière mise à jour le {dayjs(doc.Timestamp).locale('fr').format('D MMMM YYYY [à] HH:mm:ss')}
              </Typography>

              <Box className="service-buttons">
                <Button
                  variant="contained"
                  color={doc.Services.Elisath ? 'success' : 'error'}
                  onClick={() => handleOpen(doc)}
                >
                  Elisath
                </Button>
                <Button
                  variant="contained"
                  color={doc.Services.MariaDB ? 'success' : 'error'}
                  onClick={() => handleOpen(doc)}
                >
                  MariaDB
                </Button>
              </Box>
            </Box>
          ))}
        </div>

        {/* Modal */}
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          onClick={(e) => {
            if (e.target === e.currentTarget) handleClose();
          }}
        >
          <Box className="modal-content">
            {/* Close Button */}
            <button className="modal-close-button" onClick={handleClose}>
              <CloseIcon />
            </button>

            {selectedService && (
              <>
                <Typography variant="h6" gutterBottom>Statut des IPs - {selectedService.ServiceTag}</Typography>
                <List sx={{ padding: 0 }}>
                  {Object.entries(selectedService.IPs).map(([ip, { reachable, description }]) => (
                    <ListItem key={ip} sx={{ padding: '4px 0' }}>
                      <ListItemText
                        primary={
                          <>
                            {reachable ? '✅ ' : '❌ '}
                            {ip} - {description}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Box>
        </Modal>
      </Box>

      {/* Footer */}
      <Box className="footer">
        Made with ♥️ by Mika
      </Box>
    </div>
  );
};

export default App;
