// firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAhYDE-mQWuDvVRMTwlw1Im_vtTOdw35x8",
  authDomain: "monitor-4c028.firebaseapp.com",
  projectId: "monitor-4c028",
  storageBucket: "monitor-4c028.appspot.com",
  messagingSenderId: "675007819644",
  appId: "1:675007819644:web:f40c35e2bbcee31e297ef9"
};

// Initialisation Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, onSnapshot };